import { CallToAction, TrackedCTA } from 'components/CallToAction'
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image'
import React from 'react'
import { prepareData } from 'utils/googleData'

export default function Hero(props: {
    title: string
    subtitle: string
    heroDescription: string
    image: {
        image: ImageDataLike
        width: number | string
        height: number | string
    }
    svgImage: string
    video?: string
    cta: {
        title: string
        url: string
    }
    cta2: {
        title: string
        url: string
    }
}) {
    const { enable, title, subtitle, description, cta, cta2, svgImage, gatsbyImage, imageStyles, video } = prepareData({
        props,
    })
    if (enable?.toLowerCase?.() === 'false' && !process.env.RENDER_DISABLED_COMPONENTS) {
        return <></>
    }
    return (
        <>
            <div className="container mx-auto max-w-7xl px-4">
                <div className="mx-auto max-w-4xl text-center">
                    <h1
                        id="overview"
                        className="mb-6 mt-8 text-center text-[2rem] leading-tight dark:rounded dark:bg-primary dark:bg-opacity-50 dark:p-4 md:mt-14 md:text-5xl lg:text-[3.5rem]"
                    >
                        <span className="font-bold text-red">{title}</span>
                    </h1>
                    <p
                        className="text-gray-700 dark:text-gray-300 mx-auto mt-4 max-w-3xl text-center text-xl font-medium leading-relaxed md:text-2xl"
                        dangerouslySetInnerHTML={{ __html: subtitle }}
                    />
                    {(cta || cta2) && (
                        <div className="mt-8 flex flex-col justify-center space-y-3 md:flex-row md:items-center md:space-y-0 md:space-x-4">
                            {cta && (
                                <TrackedCTA
                                    to={cta.url}
                                    size="lg"
                                    className="text-lg font-medium md:min-w-[220px]"
                                    event={{ name: cta?.event }}
                                >
                                    {cta.title}
                                </TrackedCTA>
                            )}
                            {cta2 && (
                                <CallToAction
                                    type="secondary"
                                    to={cta2.url}
                                    size="lg"
                                    className="text-lg font-medium md:min-w-[220px]"
                                >
                                    {cta2.title}
                                </CallToAction>
                            )}
                        </div>
                    )}
                </div>

                {video ? (
                    <div className="relative mx-auto mt-12 mb-8 max-w-5xl text-center">
                        <div className="relative aspect-video w-full overflow-hidden rounded-lg shadow-2xl">
                            <video autoPlay muted loop playsInline className="h-full w-full object-cover">
                                <source src={`/static/${video}`} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                ) : gatsbyImage ? (
                    <div style={imageStyles} className="relative mx-auto mt-12 mb-8 max-w-5xl text-center">
                        <GatsbyImage alt={title} image={gatsbyImage} className="rounded-lg shadow-2xl" />
                    </div>
                ) : (
                    svgImage && (
                        <div style={imageStyles} className="relative mx-auto mt-12 mb-8 max-w-5xl text-center">
                            <img alt={title} className="w-full rounded-lg shadow-2xl" src={svgImage} />
                        </div>
                    )
                )}
            </div>
        </>
    )
}
